import { Button, Modal, Tabs, Tooltip, Input, Switch, Space, Badge } from 'antd';
import { React, Component } from 'react';
import ImageProvider from '../imageprovider/imageprovider';
import './combomodal.scoped.css'
const { TextArea } = Input;


class ComboModal extends Component {
    searchDirtyComboCondimentItem = (currentItem) => {
        let currentComboCondiments = this.props.currentComboCondiments
        let targetCondiment = currentComboCondiments.find((searchItem) => searchItem.item.id === currentItem.id)
        return currentComboCondiments.indexOf(targetCondiment)
    }
    totalIngredientAmount = () => {
        let totalAmount = 0
        totalAmount = this.props.currentComboCondiments.reduce((currentAmount, comboCondiment) => {

            let menuComboCondimentItem = Number(comboCondiment.item.extra_price)
            let condimentOrderQuantity = Number(comboCondiment.quantity)
            return currentAmount + (
                menuComboCondimentItem * condimentOrderQuantity
            )
        }, 0)
        return Number(totalAmount) + Number(this.props.currentMenuCombo.price1)
    }
    requiredItemCount = (currentCategoryFrag) => {
        let requiredItem = this.props.dirtyRequiredMenuComboFragment.find((required) => required.id === currentCategoryFrag.id)
        let activeItems = this.props.currentComboCondiments.filter((activeItem) => activeItem.item.menu_combo_fragment_id === currentCategoryFrag.id)
        let requiredItemAmount = requiredItem?.selection ?? 0
        let activeAmount = activeItems.reduce((accumulate, activeItem) => accumulate + activeItem.quantity, 0)
        return activeAmount > requiredItemAmount ? 0 : (requiredItemAmount - activeAmount)
    }
    disablAddAmount = (requiredAmount, fragmentGroupId) => {
            return this.props.currentComboCondiments.reduce((accm, activeItem) => 
                        activeItem.item.menu_combo_fragment_id === fragmentGroupId ? accm + activeItem.quantity : accm, 0) === requiredAmount
    }
    render() {
        const { currentMenuCombo, currentComboCondiments, updateDirtyComboCondiment, updateComboDirtyItemMethod, updateComboAddonText, dirtyRequiredMenuComboFragment, addCombo, visible, hideModal } = this.props
        return <Modal className='combo-modal-box' bodyStyle={{ height: '600px', overflowY: 'scroll' }}
            centered
            footer={
                <div className='combo-modal-footer'>
                    <div className='item-requirement'>
                        {
                            dirtyRequiredMenuComboFragment.map((requiredItem, index) => {
                                return (
                                    <div key={`requiredItem-${index}`}>
                                        <span className='required'>{`※ Require at least ${requiredItem?.selection} of ${requiredItem?.name}`}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Button type='primary' onClick={addCombo}>ORDER NOW</Button>
                </div>
            }
            onCancel={hideModal}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText="Order Now"
            visible={visible}>
            <div className="combo-item-summary" style={{ height: currentComboCondiments.length ? '40%' : 'auto' }}>
                <div className="content-topic">
                    <span className='title-text'>Ingredients</span>
                    <span className='title-text price'>RM {this.props.currentMenuCombo && this.totalIngredientAmount().toFixed(2)}</span>
                </div>
                <div className="content-items">
                    {
                        currentComboCondiments.map((comboCondiment, index) => <Tooltip title={comboCondiment.item.menu_descs1} key={`combocondiment-${index}`}>
                            <div className="menu-item">
                                <div className="combo-image">
                                    <ImageProvider src={`${process.env.REACT_APP_BACKEND_URL}/uploads/menu-items/${comboCondiment.item.menu_combo_fragment_menu_item.image}`} style={{padding: '0px'}}/>
                                </div>
                                <span className="menu-title subtitle-text">{comboCondiment.item.menu_descs1}</span>
                                <span className="subtitle-text">{`x${comboCondiment.quantity}`}</span>
                            </div>
                        </Tooltip>)
                    }
                </div>
            </div>
            <div className='combo-select-page'>
                <Tabs>
                    {
                        currentMenuCombo?.menu_combo_fragment.map((menuComboFrag, index) => {
                            return (
                                <Tabs.TabPane className='tab-page' tab={<Badge size='small' count={this.requiredItemCount(menuComboFrag)}>{menuComboFrag.name}</Badge>} key={`tabcombopane-${menuComboFrag.id}`}>
                                    {
                                        menuComboFrag.menu_combo_fragment_item.map((menuComboFragItem) => {
                                            let dirtyComboIndex = this.searchDirtyComboCondimentItem(menuComboFragItem)
                                            let currentAmount = dirtyComboIndex !== -1 ? currentComboCondiments[dirtyComboIndex].quantity : 0
                                            return (
                                                <div className='combo-item-selection' key={`tabcombopage-${menuComboFragItem.id}`}>
                                                    <div className="combo-image">
                                                        <ImageProvider src={`${process.env.REACT_APP_BACKEND_URL}/uploads/menu-items/${menuComboFragItem.menu_combo_fragment_menu_item.image}`}/>
                                                    </div>
                                                    <div className="combo-item-content">
                                                        <div className="combo-layout-text">
                                                            <span className='title-text'>{menuComboFragItem.menu_descs1}</span>
                                                            <br />
                                                            <span className='subtitle-text'>{`RM ${Number(menuComboFragItem.extra_price)}`}</span>
                                                        </div>
                                                        <div className="combo-layout-btn">
                                                            <Button type='primary' onClick={() => updateDirtyComboCondiment(menuComboFragItem, -1)} disabled={currentAmount === 0}>-</Button>
                                                            <span className='btn-value'> {currentAmount} </span>
                                                            <Button type='primary' onClick={() => updateDirtyComboCondiment(menuComboFragItem, +1)} disabled={this.disablAddAmount(menuComboFrag.selection, menuComboFrag.id)}>+</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Tabs.TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
            <div className="extras-page">
                <br /><br />
                <span className='title-text'>Special intruction</span>
                <div className="subtitle2-text">Please let us know us your specific needs</div>
                <TextArea onChange={(event) => updateComboAddonText(event)} rows={5} placeholder="e.g. extra sauce" value={this.props.updateComboAddonText(undefined,true)}/>

                <br /><br />
                <Space direction='vertical'>
                    <span className=' title-text'>Order Takeout</span>
                    <Switch onChange={(event) => updateComboDirtyItemMethod(event)} checkedChildren="Take out" unCheckedChildren="Dine In" checked={this.props.updateComboDirtyItemMethod(undefined,true)}/>
                </Space>
            </div>
        </Modal>
    }
}

export default ComboModal;