import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StorageService from '../../services/public/storageservice';
import ImageProvider from '../../components/imageprovider/imageprovider';
import JPLoader from '../../assets/images/loader_jp.gif'
import './loading.scoped.css';

function Loading() {
    const storageService = new StorageService()
    const loadingStatus = useSelector((state) => state.appstat.isLoading)

    const findShopImage = () => JSON.parse(storageService.retrieveInfo('outlet'))?.image

        return (
            <React.Fragment>
                <div>
                    <div className='loading-container' style={{display: loadingStatus? 'flex' : 'none',alignItems: 'center'}}>
                       {
                         <ImageProvider src={`${process.env.REACT_APP_BACKEND_URL}/uploads/outlet/${findShopImage()}`} defaultImage={JPLoader} style={{height: '25%',borderRadius: '50%'}}/>
                       }
                    </div>
                    <Outlet/>
                </div>
            </React.Fragment>
        );
}

export default Loading;