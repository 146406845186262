import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import ImageOnLoad from '../../assets/images/image_onload.gif';
import NoImageAvailable from '../../assets/images/no_image.png';
import './imageprovider.scoped.css';
import { useCallback } from "react";


const ImageProvider = ({ src, defaultImage, style }) => {
  const [imageBlob, setImageBlob] = useState(ImageOnLoad);

  ImageProvider.prototype = {
    src: PropTypes.string.isRequired,
    defaultImage: PropTypes.string,
  }

  const setDefaultImage = useCallback(() => {
    setImageBlob(defaultImage ?? NoImageAvailable)
  },[defaultImage])

  useEffect(() => {
    async function fetchImage(imageLink) {
      try {
        let validImagePattern = /(\.gif|\.jpeg|\.jpg|\.png)$/;
        if (validImagePattern.test(imageLink)) {
          const response = await fetch(imageLink)
          const blob = await response.blob()
          setImageBlob(URL.createObjectURL(blob))
        } else {
          setDefaultImage()
        }
      } finally {
        URL.revokeObjectURL(imageLink)
      }
    }

    fetchImage(src)
  }, [src,setDefaultImage])

  return (
    <img src={imageBlob}
      onError={(err) => setDefaultImage()}
      className="image-container"
      alt="shopitems.png"
      style={{ ...style }}
    />
  );
};

export default ImageProvider;