import React, { Component } from 'react';
import ImageProvider from '../imageprovider/imageprovider';
import './comboitem.scoped.css';


class ComboItemCarousel extends Component {
    render() {
        return <div className='combo-carousel'>
            {
                this.props.menuCombos.map((item) => 
                    <div className='combo-container' key={`comboItem-${item.id}`} onClick={() => this.props.selectMenuCombo(item)}>
                        <div className='combo-content algn-center jfy-end'>
                            <div className='combo-menu-item algn-center jfy-center'>
                                <ImageProvider src={`${process.env.REACT_APP_BACKEND_URL}/uploads/menu-items/${item.image}`} style={{height: '180px'}}/>
                            </div>
                            <div className="combo-menu-item-content">
                                <div className='item-title'>{item.name1}</div>
                                <div className='item-description'>{item.name2}</div>
                                <div className='item-description'>{`Starting price from RM ${item.price1}`}</div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    }
}

export default ComboItemCarousel;